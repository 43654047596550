import { mapMutations, mapState } from 'vuex';

import KnTable from '../../../shared/components/KnTable.vue';

import { getItem } from '@/api/api-methods';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import { canAdd } from '../../../shared/helpers/permissionsUtils';

export default {
  components: { KnTable },
  mixins: [paginationMixin],
  data() {
    return {
      headers: [
        {
          text: 'Producto',
          value: 'producto.nombre_producto',
          class: 'purple--text',
        },
        {
          text: 'Opcion',
          value: 'opcion_producto.nombre_opcion',
          class: 'purple--text',
        },
        { text: 'Unidad', value: 'unidad_medida.dato', class: 'purple--text' },
        {
          text: 'Disponible',
          value: 'cantidad_disponible',
          class: 'purple--text',
        },
        { text: 'ID', value: 'id', class: 'purple--text' },
        { text: '', value: 'acciones' },
      ],
      items: [],
      loading: false,
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'warehouse', 'search']),
  },
  watch: {
    search: {
      async handler() {
        if (this.loading) return;
        await this.delay(500);
        await this.getExpenses();
      },
    },
  },
  async created() {
    this.setIsLogin(false);
    await this.getInventories();
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    canAdd: canAdd,
    async getInventories() {
      try {
        this.loading = true;
        const limitFilter =
          this.pagination.limit !== null
            ? `&limit=${this.pagination.limit}`
            : '';
        const offsetFilter =
          this.pagination.offset !== 0
            ? `&offset=${this.pagination.offset}`
            : '';
        const searchFilter =
          this.search !== null ? `&nombre_producto=${this.search}` : '';

        const url = `/app-inventarios/filters/inventario?institucion_educativa=${this.institutionId}&estatus_sistema=true${offsetFilter}${limitFilter}${searchFilter}`;
        const responseData = await getItem(url);
        this.items = responseData.results;
        this.setPaginationCount(responseData.count);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        this.loading = false;
      }
    },
    async changePageInventory(page = 1) {
      this.setPaginationPage(page);
      await this.getInventories();
    },
  },
};
