<template>
  <v-menu bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list dense>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          link
          :disabled="item.disabled"
          @click="item.action"
        >
          <v-list-item-title>
            <span :class="item.disabled ? 'graymedio--text' : 'purple--text'">
              {{ item.title }}
            </span>
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style>
.color-text-purple {
  color: var(--v-purple-base);
}
</style>
