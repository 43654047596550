<template>
  <section>
    <v-container>
      <v-row :class="fullWidth ? 'pt-5' : 'pt-15'" justify="center">
        <v-col :cols="fullWidth ? 12 : 10">
          <v-card tile elevation="0">
            <v-card-title>
              {{ tableTitle }}
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                class="mr-3"
                append-icon="mdi-magnify"
                label="Buscar"
                clearable
                single-line
                hide-details
                :disabled="disableSearch"
                @input="setSearch(search)"
                @click:clear="clearSearch"
                @click:append="emitSearch"
              />
              <v-btn
                v-if="!showInactiveItems"
                class="mt-3"
                tile
                color="primary"
                :to="{ name: routeName }"
                :disabled="disableBtnCreate"
              >
                + {{ newButtonLabel }}
              </v-btn>
            </v-card-title>
            <v-data-table
              v-model="selected"
              :show-select="showSelect"
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              loading-text="Cargando... Espera por favor"
              no-data-text="Aún no hay registros"
              no-results-text="No se encontraron coincidencias"
              :hide-default-footer="hideDefaultFooter"
              :footer-props="{
                itemsPerPageText: 'Elementos por página',
              }"
            >
              <template v-slot:[`item.avatar`]="{ item }">
                <v-avatar
                  v-if="item.avatar.includes('digitaloceanspaces.com')"
                  size="30"
                >
                  <v-img :src="item.avatar" width="30" />
                </v-avatar>
                <v-avatar v-else size="30">
                  <v-img
                    :src="require(`../../../assets/images/${item.avatar}`)"
                    width="30"
                  />
                </v-avatar>
              </template>
              <template v-slot:[`item.foto`]="{ item }">
                <v-avatar size="38">
                  <v-img :src="item.foto" />
                </v-avatar>
              </template>
              <template v-slot:[`item.acciones`]="{ item }">
                <kn-menu :items="menuOptions(item)" />
              </template>
              <template v-slot:[`item.download`]="{ item }">
                <template v-if="tutorHasDebts">
                  <v-tooltip bottom color="amber accent-4">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark v-bind="attrs" v-on="on">
                        mdi-download-lock
                      </v-icon>
                    </template>
                    <span class="brown--text"
                      >Descarga bloqueda. Asegúrate de no tener adeudos para
                      poder descargar la boleta.</span
                    >
                  </v-tooltip>
                </template>
                <template v-else-if="item.documento || item.pdf">
                  <a :href="item.documento || item.pdf" download>
                    <v-icon
                      @click.stop="downloadDocument(item.documento || item.pdf)"
                    >
                      mdi-download
                    </v-icon>
                  </a>
                </template>
                <template v-else>
                  <span> Sin descarga </span>
                </template>
              </template>
              <template v-slot:[`item.estatus_orden.dato`]="{ item }">
                <v-chip :color="getChipColor(item.estatus_orden.dato)" outlined>
                  {{ item.estatus_orden.dato }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        :class="`pt-0 ${hideDefaultFooter ? '' : 'mt-n16'}`"
        justify="center"
      >
        <v-col
          v-if="showBottomAction"
          :cols="`${hideDefaultFooter ? 12 : 10}`"
          :md="`${hideDefaultFooter ? 5 : 10}`"
          :lg="`${hideDefaultFooter ? 5 : 10}`"
          :xl="`${hideDefaultFooter ? 5 : 10}`"
        >
          <div
            class="d-flex justify-center justify-xs-center justify-sm-center justify-md-start justify-lg-start justify-xl-start"
          >
            <v-btn
              v-if="showSelect && selected.length"
              text
              color="primary"
              @click="emitSelected"
            >
              <span class="text-capitalize mr-2">
                {{ selectedActionText }}
              </span>
            </v-btn>
            <v-btn text color="primary" @click="emitShowAction">
              <span class="text-capitalize mr-2">
                {{ bottomText }}
              </span>
            </v-btn>
          </div>
        </v-col>
        <v-col
          v-if="hideDefaultFooter"
          :cols="`${showBottomAction ? 12 : 10}`"
          :md="`${showBottomAction ? 5 : 10}`"
          :lg="`${showBottomAction ? 5 : 10}`"
          :xl="`${showBottomAction ? 5 : 10}`"
        >
          <v-pagination
            v-if="paginationCount"
            class="float-md-right float-lg-right float-xl-right"
            v-model="page"
            total-visible="10"
            :length="paginationCount"
            @input="emitChangePage()"
          />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import KnMenu from '../../shared/components/KnMenu.vue';
import { paginationMixin } from '../../shared/mixins/paginationMixin';
import { Actions } from '../helpers/permissionContants';
import { can } from '../helpers/permissionsUtils';
export default {
  components: { KnMenu },
  mixins: [paginationMixin],
  props: {
    tableTitle: {
      type: String,
      default: '',
    },
    rememberLastPage: {
      type: Boolean,
      default: false,
    },
    newButtonLabel: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disableBtnCreate: {
      type: Boolean,
      default: false,
    },
    showBottomAction: {
      type: Boolean,
      default: false,
    },
    showInactiveItems: {
      type: Boolean,
      default: false,
    },
    bottomActionText: {
      type: String,
      default: undefined,
    },
    selectedActionText: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    paginationCount: {
      type: Number,
      default: 0,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    disableSearch: {
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      search: null,
      selected: [],
    };
  },
  computed: {
    ...mapState(['currentPage']),
    ...mapGetters(['tutorHasDebts', 'hasAdminPermission']),
    bottomText() {
      return this.bottomActionText
        ? this.bottomActionText
        : `Ver ${this.tableTitle} inactivos`;
    },
    section() {
      if (this.newButtonLabel.includes('Asignar')) {
        return 'Estimulacion Temprana';
      } else if (this.newButtonLabel.includes('alumno')) {
        return 'Alumno';
      } else if (this.newButtonLabel.includes('empleado')) {
        return 'Empleado';
      } else if (this.newButtonLabel.includes('ingreso')) {
        return 'Ingresos';
      } else if (this.newButtonLabel.includes('egreso')) {
        return 'Egresos';
      } else if (this.newButtonLabel.includes('proveedor')) {
        return 'Proveedor';
      } else if (this.newButtonLabel.includes('producto')) {
        return 'Producto';
      } else if (this.newButtonLabel.includes('orden')) {
        return 'Ordenes';
      } else if (this.newButtonLabel.includes('inventario')) {
        return 'Inventario';
      } else if (this.newButtonLabel.includes('grupo')) {
        return 'Grupo';
      } else if (this.newButtonLabel.includes('anuncio')) {
        return 'Anuncio';
      } else if (this.newButtonLabel.includes('boleta')) {
        return 'Boleta';
      } else if (this.newButtonLabel.includes('plan')) {
        return 'Planes de estudio';
      } else if (this.newButtonLabel.includes('calificacion')) {
        return 'Calificacion';
      }
      return '';
    },
    routeName() {
      if (this.newButtonLabel.includes('Asignar alumno')) {
        return 'Nuevo Estimulacion Temprana';
      } else if (this.newButtonLabel.includes('Asignar materia')) {
        return 'Nuevo Materias Profesor';
      } else if (this.newButtonLabel.includes('alumno')) {
        return 'Nuevo Alumno';
      } else if (this.newButtonLabel.includes('empleado')) {
        return 'Nuevo Empleado';
      } else if (this.newButtonLabel.includes('ingreso')) {
        return 'Agregar Ingresos';
      } else if (this.newButtonLabel.includes('egreso')) {
        return 'Agregar Egresos';
      } else if (this.newButtonLabel.includes('proveedor')) {
        return 'Nuevo Proveedor';
      } else if (this.newButtonLabel.includes('producto')) {
        return 'Nuevo Producto';
      } else if (this.newButtonLabel.includes('orden')) {
        return 'Nuevo Ordenes';
      } else if (this.newButtonLabel.includes('inventario')) {
        return 'Nuevo Inventario';
      } else if (this.newButtonLabel.includes('anuncio')) {
        return 'Nuevo Anuncio';
      } else if (this.newButtonLabel.includes('boleta')) {
        return 'Nueva Boleta';
      } else if (this.newButtonLabel.includes('plan')) {
        return 'Nuevo Plan';
      }
      return '';
    },
  },
  watch: {
    paginationCount: function () {
      if (this.rememberLastPage) {
        this.page = this.currentPage || 1;
        this.setCurrentPage(this.currentPage);
      } else {
        this.page = 1;
      }
    },
  },
  methods: {
    ...mapMutations(['setSearch', 'setSelectedId', 'setCurrentPage']),
    ...mapActions(['clearSearch']),
    downloadDocument(url) {
      url
        ? window.open(url, '_blank')
        : console.error('La URL del documento es inválida.');
    },
    getChipColor(estatus) {
      switch (estatus) {
        case 'Orden Creada':
          return 'blue';
        case 'Orden Aceptada':
          return 'green';
        case 'Orden Rechazada':
          return 'red';
        case 'Orden Cancelada':
          return 'orange';
        case 'Orden pendiente de Surtir':
          return 'yellow';
        case 'Orden Surtida':
          return 'purple';
        case 'Orden Pagada':
          return 'teal';
        case 'Orden Facturada':
          return 'brown';
        case 'Orden Completa':
          return 'grey';
        default:
          return 'black'; // Default color for unknown status
      }
    },
    menuOptions(item) {
      return [
        {
          title: 'Editar',
          action: () => this.goTo(`Editar ${this.section}`, { entity: item }),
          section: [
            'Alumno',
            'Empleado',
            'Proveedor',
            'Producto',
            'Anuncio',
            'Boleta',
            'Estimulacion Temprana',
            'Materias Profesor',
          ],
          permissions: [Actions.change],
          active: true,
          disabled: false,
        },
        {
          title: 'Listar alumnos a calificar',
          action: () =>
            this.goTo(`Calificar alumnos`, { id: item.id, entity: item }),
          section: ['Calificacion'],
          permissions: [Actions.view, Actions.change],
          active: true,
          disabled: false,
        },
        {
          title: 'Estado de cuenta',
          action: () =>
            this.goTo(`Estado de Cuenta ${this.section}`, { entity: item }),
          section: ['Alumno', 'Empleado'],
          permissions: [Actions.view],
          active: true,
          disabled: false,
        },
        {
          title: 'Ver boletas',
          action: () => this.emitActionResults(item.documentos_boletas),
          section: ['Alumno'],
          permissions: [Actions.view],
          active: true,
          disabled: false,
        },
        {
          title: 'Ver detalle',
          action: () =>
            this.goTo(`Editar ${this.section}`, {
              entity: item,
              readonly: true,
            }),
          section: ['Ingresos', 'Egresos'],
          permissions: [Actions.view],
          active: true,
          disabled: false,
        },
        {
          title: 'Ver detalle',
          action: () =>
            this.goTo(`Editar Plan`, {
              entity: item,
              readonly: true,
            }),
          section: ['Planes de estudio'],
          permissions: [],
          active: true,
          disabled: false,
        },
        {
          title: 'Ver detalle',
          action: () => this.goTo('Detalle Orden', { entity: item }),
          section: ['Ordenes'],
          permissions: [],
          active: true,
          disabled: false,
        },
        {
          title: 'Corregir',
          action: () =>
            this.emitAction({
              src: this.section,
              itemId: item.id,
              groupId: item.groupId,
            }),
          section: ['Ingresos', 'Egresos'],
          permissions: [Actions.change],
          active: true,
          disabled: false,
        },
        {
          title: 'Editar',
          action: () =>
            this.emitAction({
              action: 'corregir',
              itemId: item.id,
            }),
          section: ['Planes de estudio'],
          permissions: [],
          active: true,
          disabled: false,
        },
        {
          title: 'Duplicar',
          action: () =>
            this.emitAction({
              action: 'duplicar',
              itemId: item.id,
            }),
          section: ['Planes de estudio'],
          permissions: [],
          active: true,
          disabled: false,
        },
        {
          title: 'Actualizar',
          action: () => this.goTo(`Editar ${this.section}`, { entity: item }),
          section: ['Inventario'],
          permissions: [Actions.change],
          active: true,
          disabled: false,
        },
        {
          title: 'Desactivar',
          action: () => this.emitAction2(item.id),
          section: [
            'Alumno',
            'Empleado',
            'Proveedor',
            // 'Producto'
            'Anuncio',
            'Materias Profesor',
          ],
          permissions: [Actions.delete],
          active: true,
          disabled: false,
        },
        {
          title: 'Desactivar',
          action: () => this.emitAction2(item.id),
          section: ['Planes de estudio'],
          permissions: [],
          active: true,
          disabled: false,
        },
        {
          title: 'Reactivar',
          action: () => this.emitAction2(item.id),
          section: ['Planes de estudio', 'Materias Profesor'],
          permissions: [],
          active: false,
          disabled: false,
        },
        {
          title: 'Boletas',
          action: () => this.goTo('Boletas', { group: item }),
          section: ['Grupo'],
          permissions: [],
          active: true,
          disabled: false,
        },
        {
          title: 'Alumnos',
          action: () => this.goTo('Alumnos', { group: item }),
          section: ['Grupo'],
          permissions: [],
          active: true,
          disabled: false,
        },
        {
          title: 'Reactivar',
          action: () => this.emitAction2(item.id),
          section: ['Alumno', 'Empleado', 'Proveedor', 'Anuncio'],
          permissions: [Actions.change],
          active: false,
          disabled: false,
        },
        {
          title: 'Cancelar orden',
          action: () => this.goTo('Solicitar Cancelacion', { entity: item }),
          section: ['Ordenes'],
          permissions: [],
          active: true,
          disabled: item.estatus_orden
            ? item.estatus_orden.dato.includes('Cancelada')
            : false,
        },
      ]
        .filter(
          (option) =>
            option.section.some((s) => s === this.section) &&
            option.active === !this.showInactiveItems
        )
        .map((option) => {
          option.permissions && option.permissions.length
            ? (option.disabled = !option.permissions.some((p) => {
                switch (this.section) {
                  case 'Ingresos':
                    return can({
                      actions: [p],
                      resource: 'ingreso',
                    });
                  case 'Egresos':
                    return can({
                      actions: [p],
                      resource: 'egreso',
                    });
                  default:
                    return can({ actions: [p], resource: this.section });
                }
              }))
            : (option.disabled = false);
          return option;
        });
    },
    goTo(routeName, params) {
      if (routeName.includes('Alumno')) {
        this.setSelectedId(params.entity.id);
        return this.$router.push({
          name: routeName,
          params: { id: params.entity.id },
        });
      }
      if (
        routeName.includes('Detalle Orden') ||
        routeName.includes('Solicitar Cancelacion')
      ) {
        this.setSelectedId(params.entity.id);
        return this.$router.push({
          name: routeName,
          params: { id: params.entity.id },
        });
      }
      if (routeName.includes('Inventario')) {
        this.setSelectedId(params.entity.id);
        return this.$router.push({
          name: routeName,
          params: { id: params.entity.id },
        });
      }
      return this.$router.push({ name: routeName, params: params });
    },
    emitAction(value) {
      this.$emit('action', value);
    },
    emitAction2(value) {
      this.$emit('action2', value);
    },
    emitShowAction() {
      this.clearSearch();
      this.$emit('showAction');
    },
    emitSelected() {
      this.$emit('selectedAction', this.selected);
    },
    emitActionResults(value) {
      this.$emit('resultsAction', value);
    },
    emitChangePage() {
      this.$emit('onChangePage', this.page);
    },
    emitSearch() {
      // console.log('Click en lupa');
      this.$emit('onSearch');
    },
    emitClearSearch() {
      this.clearSearch();
      this.$emit('onClearSearch');
    },
  },
};
</script>

<style></style>
