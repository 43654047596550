// import { mapMutations, mapState } from 'vuex';

export const paginationMixin = {
  data() {
    return {
      count: 0,
      limit: 5,
      page: 1,
    };
  },
  computed: {
    // ...mapState(['currentPage']),

    lastPage() {
      return Math.ceil(this.count / this.limit);
    },
    pagination() {
      return {
        currentPage: this.page ,
        lastPage: this.lastPage,
        previousPage: this.page > 1 ? this.page - 1 : this.page,
        nextPage: this.page < this.lastPage ? this.page + 1 : this.page,
        offset: this.page > 1 ? (this.page - 1) * this.limit : 0,
        limit: this.limit,
        count: this.count,
      };
    },
  },
  methods: {
    // ...mapMutations(['setCurrentPage']),

    initPagination(count = 0, limit = 5, page = 1) {
      this.count = count;
      this.limit = limit;
      this.page = page;
    },
    resetPagination() {
      this.count = 0;
      this.limit = 5;
      this.page = 1;
    },
    setPaginationCount(value) {
      this.count = value;
    },
    setPaginationLimit(value) {
      this.limit = value;
    },
    setPaginationPage(value ) {
      this.page = value;
      // this.setCurrentPage(value)
    },
  },
};
